<template>
  <div style="margin-top: 10px">
    <div style="background-color: #ffffff">
      <el-dialog title="停用商铺" :visible.sync="flag" width="422px" :append-to-body="true">
        <el-form :model="ruleForm" :rules="rules"ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <div style="font-size: 16px">确定要停用吗</div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="flag = false">取 消</el-button>
          <el-button type="primary" @click="sure">确 定</el-button>
        </span>
      </el-dialog>
      <el-dialog title="启用商铺":visible.sync="flag2" width="422px" :append-to-body="true">
        <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-width="100px" class="demo-ruleForm">
          <div style="font-size: 16px">确定要启用吗</div>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="flag2 = false">取 消</el-button>
          <el-button type="primary" @click="sure2">确 定</el-button>
        </span>
      </el-dialog>
      <div style="padding: 60px">
        <div class="shop">
          <div class="headline">门店信息</div>
          <div style="margin-left: 20px">
            注册手机号：
            <span style="margin-left: 20px">{{ storeFrom.mobile }}</span>
          </div>
        </div>

        <div style="margin: 20px"></div>
        <el-form label-width="130px" style="width: 100%" :label-position="labelPositiona" :rules="rules" ref="storeFrom" :model="storeFrom">
          <el-form-item label="店铺名称：" style="margin-left: 74px" prop="name">
            <el-input v-model="storeFrom.name" style="margin-left: 10px;"></el-input>
          </el-form-item>
          <el-form-item label="店铺详情地址：" style="margin-left: 74px" prop="address">
            <el-input v-model="storeFrom.address" style="margin-left: 10px;"></el-input>
          </el-form-item>
          <el-form-item label="门头照：" style="margin-left: 74px" prop="frontImg">
            <div style="display: inline-block">
              <el-upload
                name="image"
                :action="imageFile"
                list-type="picture-card"
                :on-remove="handleRemove"
                :on-success="frontImg"
                :file-list="fileListFront"
                :headers="headers"
                :on-preview="handlePictureCardPreview"
                :limit="1"
                style="margin-left: 10px;"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog
                :visible.sync="dialogVisible"
                :modal-append-to-body="false"
              >
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </el-form-item>
          <el-form-item
            label="店内照："
            style="margin-left: 74px"
            prop="innerImg"
          >
            <div style="display: inline-block">
              <el-upload
                name="image"
                :action="imageFile"
                list-type="picture-card"
                :on-remove="handleRemove"
                :on-success="innerImg"
                :file-list="fileListInner"
                :headers="headers"
                :on-preview="handlePictureCardPreview"
                :limit="1"
                style="margin-left: 10px;"
              >
                <i class="el-icon-plus"></i>
              </el-upload>
              <el-dialog
                :visible.sync="dialogVisible"
                :modal-append-to-body="false"

              >
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
            </div>
          </el-form-item>
          <el-form-item label="联系人：" style="margin-left: 74px" prop="contact">
            <el-input v-model="storeFrom.contact"  style="margin-left: 10px;"></el-input>
          </el-form-item>
          <el-form-item label="联系电话：" style="margin-left: 74px" prop="phone"
          >
            <el-input v-model="storeFrom.phone"  style="margin-left: 10px;"></el-input>
          </el-form-item>
          <el-form-item label="分店数量：" style="margin-left: 74px" prop="subCount">
            <el-input style="margin-left: 10px;" type="number" oninput="if(value>999)value=999" v-model="storeFrom.subCount"></el-input>
          </el-form-item>
          <el-form-item label="合作期限：" style="margin-left: 84px" prop="period">
            <span>
              {{ storeFrom.period }}
            </span>
          </el-form-item>
          <el-form-item label="入驻时间：" style="margin-left: 84px">
            <div class="shop">
              <div>{{ storeFrom.accountData.createTime }}</div>
              <div style="margin-left: 20px">到期时间：{{ endTime }}</div>
            </div>
          </el-form-item>
          <el-form-item label="BD邀请码：" style="margin-left: 84px" prop="invokeCode">
          <input type="input"  name="" id="" v-model="storeFrom.invokeCode" />
          </el-form-item>
         <div class="headline">资质信息</div>
         <div class="across" style="margin-bottom: 20px"></div>
         <div style="margin-left: 85px">
           <el-form-item label="主体类型：" prop="licenseData.type">
             <el-radio-group v-model="storeFrom.licenseData.type" @change="cervicaltrue(storeFrom.licenseData.type)">
               <el-radio :label="2">企业</el-radio>
               <el-radio :label="4">个体经商户</el-radio>
               <el-radio :label="2500">个人卖家</el-radio>
             </el-radio-group>
           </el-form-item>
           <div v-if="storeFrom.licenseData.type!==2500">
             <el-form-item label="营业执照：" prop="licenseData.licenseImg" ref="uploads">
               <el-upload
                 name="licenseImg"
                 :action="licenseFile"
                 list-type="picture-card"
                 :on-remove="licenseRemove"
                 :on-success="licenseImg"
                 :limit="1"
                 :on-exceed="exceed"
                 :headers="headers"
                 :on-preview="handlePictureCardPreview"
                 :before-upload="beforeAvatarUpload"
                 :file-list="fileListLicense"

               >
                 <i class="el-icon-plus"></i>
               </el-upload>
               <el-dialog :visible.sync="dialogVisible" :modal-append-to-body="false">
                 <img width="100%" :src="dialogImageUrl" alt="" />
               </el-dialog>
             </el-form-item>
             <el-form-item label="注册号：" prop="licenseData.certNo">
               <el-input
                 v-model="storeFrom.licenseData.certNo"
               ></el-input>
             </el-form-item>
             <el-form-item label="公司名称：" prop="licenseData.name" :rules="[{ required: true, message: '请填写商户名称', trigger: 'blur' },]">
               <el-input v-model="storeFrom.licenseData.name"></el-input>
             </el-form-item>
             <el-form-item label="注册地址：" prop="licenseData.address":rules="[{ required: true, message: '请填写注册地址', trigger: 'blur' },]"
             >
               <el-input v-model="storeFrom.licenseData.address"></el-input>
             </el-form-item>
             <el-form-item label="法定代表人：" prop="licenseData.legalRepName">
               <el-input v-model="storeFrom.licenseData.legalRepName"></el-input>
             </el-form-item>
             <el-form-item label="营业期限：" v-if="storeFrom.licenseData.type==2" prop="licenseData.scope">
               <el-radio-group
                 v-model="storeFrom.licenseData.scope"
                 @change="businesstrue(storeFrom.licenseData.scope)"
               >
                 <el-radio :label="1">长期有效</el-radio>
                 <el-radio :label="2">固定有效期</el-radio>
               </el-radio-group>
             </el-form-item>
             <div v-if="storeFrom.licenseData.scope == 1 &&storeFrom.licenseData.type==2">
               <el-form-item  prop="licenseData.validTermStart" :rules="[{ required: true, message: '请填写开始日期', trigger: 'blur' },]">
                 <el-date-picker v-model="storeFrom.licenseData.validTermStart" type="date" value-format="yyyy-MM-dd" placeholder="开始日期">
                 </el-date-picker>
               </el-form-item>
             </div>
             <div v-if="storeFrom.licenseData.scope == 2 && storeFrom.licenseData.type==2">
               <el-form-item  prop="licenseData.validTermStart"
                   :rules="[{ required: true, message: '请填写开始日期', trigger: 'blur' },]">
                 <el-date-picker v-model="storeFrom.licenseData.validTermStart" type="date" placeholder="开始日期" value-format="yyyy-MM-dd">
                 </el-date-picker>
                 </el-form-item>
                 <el-form-item  prop="licenseData.validTermStart" :rules="[{ required: true, message: '请填写结束日期', trigger: 'blur' },]">
                 <el-date-picker v-model="storeFrom.licenseData.validTermEnd" type="date" value-format="yyyy-MM-dd" placeholder="结束日期">
                 </el-date-picker>
               </el-form-item>
             </div>
             <div style="margin-left: 10px;">
               <el-form-item
                 label="其他资质："
                 prop="licenseData.licenseAddition"
               >
                 <el-upload
                    name="image"
                   :action="privacy"
                   list-type="picture-card"
                   :on-remove="aptitudeRemove"
                   :on-success="aptitudeFile"
                   :headers="headers"
                   :on-preview="handlePictureCardPreview"
                   :limit="9"
                   :file-list="fileListAddition"
                   :before-upload="beforeAvatarUpload"
                   :multiple="true"
                 >
                   <i class="el-icon-plus"></i>
                 </el-upload>
                 <el-dialog
                   :visible.sync="dialogVisible"
                   :modal-append-to-body="false"
                 >
                   <img width="100%" :src="dialogImageUrl" alt="" />
                 </el-dialog>
               </el-form-item>
             </div>
           </div>
         </div>

          <div class="headline">结算信息</div>
          <div class="across" style="margin-bottom: 20px"></div>
          <el-form-item label="开店人身份信息：" style="margin-left: 84px">
            <div style="width: 100%; display: inline-block">
              <img :src="FrontSideFileList" alt="" style="width: 300px; height: 150px"/>
              <img :src="sideFileList" alt="" style="width: 300px; height: 150px; margin-left: 5%"/>
            </div>
          </el-form-item>
          <el-form-item label="姓名(户名)：" style="margin-left: 84px">
            <span> {{ storeFrom.bankCardOwner }}</span>
          </el-form-item>
          <el-form-item label="身份证：" style="margin-left: 84px">
            <span>{{ storeFrom.accountData.cardNo }}</span>
          </el-form-item>
          <el-form-item label="开户行：" style="margin-left: 84px">
            <span>{{ storeFrom.bankName }}</span>
          </el-form-item>
          <el-form-item label="银行卡号：" style="margin-left: 84px">
            <span> {{ storeFrom.bankCardNo }}</span>
          </el-form-item>
          <el-form-item label="银行预留手机号：" style="margin-left: 84px">
            <span>{{ storeFrom.accountData.reservationMobile }}</span>
          </el-form-item>
        </el-form>
        <div slot="footer" class="foot">
          <el-button v-show="storeFrom.status != 6" type="primary" style="margin-left: 30px" @click="storeEdit('storeFrom')">
            <span>提交</span>
          </el-button>
          <el-button type="primary" v-show="storeFrom.enabled == true && storeFrom.status != 6" style="margin-left: 30px" @click="deactivate">停用</el-button>
          <el-button v-show="storeFrom.enabled == false && storeFrom.status != 6" type="primary" style="margin-left: 30px" @click="initiate">启用</el-button
          >
          <router-link to="/store/list">
            <el-button style="margin-left: 30px">返 回</el-button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <!-- 添加 -->
</template>
<script>
import url from "@/utils/file";

import moment from "moment";
import { getStore } from "@/utils/storage";
import {
  storeEdit,
  storeDetail,
  uploadGetStsUrl,
  storeStatus,
} from "@/api/store";

export default {
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3-9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    var subCount = (rule, value, callback) => {
      const idNumberReg = /^[+]{0,9}(\d+)$/;
      if (!value) {
        return callback(new Error("请输入分店数量"));
      }
      setTimeout(() => {
        if (!idNumberReg.test(value)) {
          return callback(new Error("您的分店数量格式错误!"));
        } else {
          callback();
        }
      }, 100);
    };
    return {
      ruleForm: {
        name: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
        ],
      },
      ruleForm2: {
        name: "",
      },
      rules2: {
        name: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
        ],

      },
      business: true, //营业期限
      businesss: false, //营业期限
      fileData: [],
      flag: false, //停用弹出框
      flag2: false,
      cervical: true, //主体类型隐藏
      cardNoperiod: 2, //判断身份证有效期限条件
      labelPositiona: "right",
      dialogVisible: false,
      cardNoperiods: true, //份证有效期限
      cardNoperiodss: false, //份证有效期限
      supervisor: false, //管理员信息同步 按钮
      idcardInfo: "", //身份证识别信息
      fileListFront: [], //门头图片回显
      fileListInner: [], //店内图片回显
      fileListAddition: [], //其他资质图片回显
      fileListLicense: [], //营业执照回显
      Start: "", //开始时间回显
      End: "", //结束时间回显
      storeFrom: {
        period: 1,
        frontImg: "",
        innerImg: "",
        licenseData: {
          certNo: "",
          name: "",
          address: "",
          legalRepName: "",
          validTermStart: "",
          validTermEnd: "",
          type: 2500,
          scope: "", //判断营业期限条件
          licenseImg: "",
          licenseAddition: [],
          storeId: "",
        },
        accountData: {
          address: "", //详情地址
          bankCardNo: "", //银行卡号
          bankName: "", //开户行
          birthDay: "", //出生日期
          cardFrontSide: "", //身份证正面
          cardNo: "", //身份证号
          cardReverseSide: "", //身份证反面
          createTime: "", //创建时间
          gender: "", //性别
          id: 0, //主键id
          issuedBy: "", //发证机关
          nation: "", //民族
          realName: "", //结算人姓名
          reservationMobile: "", //预留电话
          storeId: 0, //店铺id
          validityEnd: "", //身份证有效期结结束
          validityStart: "", //身份证有效期开始
          verifyId: "", //e签宝业务id
        },
      },
      imageFile: url.url_image, //上传image
      licenseFile: url.url_license, //营业执照
      idcardFile: url.url_idcard, //身份证
      privacy: url.url_privacy,//资质上传
      rules: {
        mobile: [
        {required: true,validator: checkPhone,trigger: "blur",},],
        name: [{required: true,message: "请填写店铺名称",trigger: "blur",},],
        address: [{required: true,message: "请填写店铺地址",trigger: "blur",},],
        frontImg: [{required: true,message: "请上传门头照",trigger: "change",},],
        innerImg: [{required: true,message: "请上传店内照",trigger: "change",},],
        contact: [{required: true,message: "请填写店铺联系人",trigger: "blur",},],
        phone: [{required: true,validator: checkPhone,trigger: "blur",},],
        subCount: [{required: true,validator: subCount,trigger: "blur",},],
        licenseImg: [{required: true,message: "请上传营业执照",trigger: "change",},],
        legalRepName: [{required: true,message: "请填写法人姓名",trigger: "blur",},],
        certNo: [{required: true,message: "请填写营业执照注册号",trigger: "blur",},],
        commercialName: [{required: true,message: "请填写商户名称",trigger: "blur",},],
      },
      submitFile: {
        arrFile: [],
      },
      dialogImageUrl: "", //照片放大
      licenseFileList: "", //营业执照回显
      FrontSideFileList: "", //身份证正面
      sideFileList: "", //身份证反面
      endTime: "", //到期时间
      cervical: true,
    };
  },
  created() {
    this.list();
  },

  methods: {
    //限制上传一张图片
    exceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件，请删除重新上传`
      );
    },

    //其他资质上传图片
    aptitudeFile(img) {
        this.storeFrom.licenseData.licenseAddition.push(img.data)
    },
    //其他资质删除图片
    aptitudeRemove(file, fileList) {
      let i = this.storeFrom.licenseData.licenseAddition.indexOf(file.url);
      this.storeFrom.licenseData.licenseAddition.splice(i, 1);
    },
    //停用
    deactivate() {
      this.flag = true;
    },
    //启用
    initiate() {
      this.flag2 = true;
    },
    //停用弹框确认
    sure() {
      let id = this.$route.query.id;
      storeStatus({
        id: id,
      }).then((res) => {
        this.flag = false;
        let id = this.$route.query.id;
        storeDetail({
          id: id,
        }).then((res) => {
          this.storeFrom = res.data;
        });
      });
    },
    //启用弹框确认
    sure2() {
      let id = this.$route.query.id;
      storeStatus({
        id: id,
      }).then((res) => {
        this.flag2 = false;
        let id = this.$route.query.id;
        storeDetail({
          id: id,
        }).then((res) => {
          this.storeFrom = res.data;
        });
      });
    },
    //限制图片大小
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    //营业执照
    licenseImg(img) {
      this.storeFrom.licenseData.licenseImg = img.data.license.substring(
        0,
        img.data.license.lastIndexOf("?")
      );
      let { certNo, name, address, legalRepName } = img.data.licenseInfo;
      this.storeFrom.licenseData.certNo = certNo;
      this.storeFrom.licenseData.name = name;
      this.storeFrom.licenseData.address = address;
      this.storeFrom.licenseData.legalRepName = legalRepName;
    },
    //删除营业执照删除
    licenseRemove() {
      this.storeFrom.licenseData.licenseImg = "";
    },
    // 资质信息判断隐藏显示
    cervicaltrue(row) {

      let self = this;
      if (row === 2500) {
        self.cervical = false;
        self.storeFrom.licenseData.validTermStart = "长期"
        self.storeFrom.licenseData.validTermEnd = "长期";
      } else if (row===2) {
        self.cervical = true;
        self.storeFrom.licenseData.validTermStart = ""
        self.storeFrom.licenseData.validTermEnd = "";
      } else if (row===4){
        self.cervical = true;
        self.storeFrom.licenseData.validTermStart = "长期"
        self.storeFrom.licenseData.validTermEnd = "长期";
      }
    },
    //营业期限显示隐藏
    businesstrue(row) {
      let self = this;
      self.business = !self.business;
      self.businesss = !self.businesss;
      if (row === 1) {
        self.storeFrom.licenseData.validTermEnd = "长期";
        self.storeFrom.licenseData.validTermStart = "";
      } else if (row === 2) {
        self.storeFrom.licenseData.validTermStart = "";
        self.storeFrom.licenseData.validTermEnd = "";
      }
    },
    //提交
    async list() {
      let id = this.$route.query.id;
      // 获取详情
      storeDetail({
        id: id,
      }).then((res) => {
        this.storeFrom = res.data;
        this.Start = this.storeFrom.licenseData.validTermStart;
        this.End = this.storeFrom.licenseData.validTermEnd;
         if( this.storeFrom.licenseData.scope!=null){
          this.storeFrom.licenseData.scope = Number(
          this.storeFrom.licenseData.scope);}

       //门头店
       let frontImg = this.storeFrom.frontImg
       //店内照
       let innerImg = this.storeFrom.innerImg
        //营业执照
        let licenseImg = this.storeFrom.licenseData.licenseImg;
        // 身份证面部
        let cardFrontSide = this.storeFrom.accountData.cardFrontSide;
        //身份证反面
        let cardReverseSide = this.storeFrom.accountData.cardReverseSide;
        //门头照
        this.fileListFront.push({
          url: frontImg,
        });
        //店内照
        this.fileListInner.push({
          url: innerImg,
        });
        //其他资质
        if(this.storeFrom.licenseData.licenseAddition!=null){
          this.storeFrom.licenseData.licenseAddition.forEach(async(item) => {
               let {data} = await uploadGetStsUrl({ filename: item, })
               this.fileListAddition.push({
               url:data,
               }
               )
          });
        }

        uploadGetStsUrl({
          filename: cardFrontSide,
        }).then((res) => {
          this.FrontSideFileList = res.data;
        });
        //身份证反面
        uploadGetStsUrl({
          filename: cardReverseSide,
        }).then((res) => {
          this.sideFileList = res.data;
        });
        //营业执照
        uploadGetStsUrl({
          filename: licenseImg,
        }).then((res) => {
          this.fileListLicense.push({
            url: res.data,
          });
        });
        let cardNo = this.storeFrom.accountData.cardNo;
        let supervisorCardNo = this.storeFrom.accountData.supervisorCardNo;
        if (cardNo === supervisorCardNo) {
          this.supervisor = true;
        }
        let timeString = this.storeFrom.accountData.createTime;
        let newtime = new Date(timeString);
        let newDate = newtime.getTime();
        let endTime = new Date(newDate);
        let y = endTime.getFullYear();
        let m = endTime.getMonth() + 1;
        let d = endTime.getDate();
        let hh = endTime.getHours();
        let mm = endTime.getMinutes();
        let ss = endTime.getSeconds();
        y = y + this.storeFrom.period;
        hh = hh < 10 ? "0" + hh : hh;
        mm = mm < 10 ? "0" + mm : mm;
        ss = ss < 10 ? "0" + ss : ss;
        this.endTime = y + "-" + m + "-" + d + " " + hh + ":" + mm + ":" + ss;
      });
    },
    handleRemove(file, fileList) {
      this.submitFile.arrFile = [];
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    //门头照
    frontImg(img) {
      let self = this;
      self.storeFrom.frontImg = img.data;
    },
    //店内照
    innerImg(img) {
      let self = this;
      self.storeFrom.innerImg = img.data;
    },
    //店铺添加
    storeEdit(storeFrom) {
      this.rules.file = [
        {
          required: true,
          message: "请上传身份证正反面",
        },
      ];
      if (this.submitFile.arrFile.length != 0) {
        this.rules.file = [];
        this.$refs["uploadElement"].clearValidate();
      }
      this.$refs[storeFrom].validate((valid) => {
        if (valid == true) {
          storeEdit(this.storeFrom).then((res) => {
            if (res.status === 10000 && res.data === true) {
              this.$router.push("/store/list");
            }
          });
        }
      });
    },
  },
  computed: {
    //图片上传请求头部
    headers() {
      return {
        Authorization: getStore("token"),
      };
    },
  },
};
</script>
<style lang="less" scoped>
i {
  margin-left: 0px;
}
</style>
<style lang="less" scoped>
.el-upload--picture-card {
  background-color: #fbfdff;
  border: 1px dashed #c0ccda;
  border-radius: 6px;
  box-sizing: border-box;
  width: 146px;
  height: 146px;
  line-height: 146px;
  vertical-align: top;
}

.headline {
  font-size: 16px;
  color: #333333;
  font-weight: bold;
}

.foot {
  text-align: center;
}

.hide .el-upload--picture-card {
  display: none;
}

.shop {
  display: flex;
}

/deep/ .el-dialog {
  display: flex;
  flex-direction: column;
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  border: 1px solid #ebeef5;
  height: 232px;
  width: 422px;
}

.el-dialog .el-dialog_body {
  flex: 1;
  overflow: auto;
}

/deep/.el-form-item__label {
  text-align: left;
}

.el-card__body {
  padding: 0px;
}
</style>
